
.checkout-main-outer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkout-inner-outer{
    -webkit-box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.75);
    box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.5);
    background-color: #fffefe;
    border: 1px solid black;
    border-radius: 5px;
    width: 40%;
    height: 80%;
    flex-direction: column;
}
.checkout-title{
    text-align: center;
}
.checkout-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.checkout-photo-outer{
    width: 50%;
    height: auto;
    /*border: 1px solid red;*/
}
.checkout-photo-outer img{
    width: 100%;
    height: 100%;
}
.description-checkout{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    flex: 1;
    padding-left: 20px;
}
.description-checkout p{
    text-align: start;
}
.description-checkout span{
    color: black;
}
.checkout-button-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.checkout-button-outer button{
    background-color: red;
    border: 1px solid white;
}

.line {
    border-bottom: 1px solid #888;
}

/*media query*/
@media (max-width:768px){
    .checkout-body{
        flex-direction: column;
    }
    .checkout-photo-outer{
        width: 100%;
        height:auto;
    }
    .checkout-inner-outer{
        width: 100%;
        height: auto;
    }
    .checkout-photo-outer img{
        width: 100%;
        height: 100%;
    }
    .description-checkout{
        margin-top: 20px;
        padding-left: 20px;
    }
    .checkout-button-outer button {
        width: 100%;
        font-size: 20px !important;
    }
}

@media (max-width: 1024px) {
    .checkout-body {
        flex-direction: column;
    }
    .checkout-photo-outer {
        width: 100%;
        height: auto;
    }
    .checkout-inner-outer {
        width: 100%;
        height: auto;
    }
    .checkout-photo-outer img {
        width: 100%;
        height: 100%;
    }
    .description-checkout {
        margin-top: 20px;
        padding-left: 20px;
    }
    .description-checkout p {
        font-size: 25px !important;
    }
    .description-checkout h4 {
        font-size: 30px !important;
    }
    .description-checkout span {
        color: black;
    }
    .checkout-button-outer {
        width: 100%;
    }
    .checkout-button-outer button {
        width: 100%;
        font-size: 25px;
    }
}
