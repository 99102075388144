.outer{
    max-width: 100% !important;
    height: auto;
    margin-bottom: 5%;
}
.outer p{
    color: #f3eded;
}
.ebook-search-outer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.me-auto{
    height: 35px;
}
.book-list{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:50px;
    margin:7px 0 30px 0;
}
.photo img{
    /*border: 1px solid blue;*/
    border-radius: 10px;
    cursor: pointer;
}
.photo:hover{
    opacity: 70%;
    transition: all 0.1s;
}
.category-buttons{
    width: 100%;
    height: auto;
    padding-top: 10px;
}

.select-type-select {
    width: 40%;
    height: 30px;
    border-radius: 3px;
}
.buttons{
    border-radius: 5px;
    margin: 5px;
    font-size:17px ;
    color: black;
}
.buttons button{
    border-radius: 5px;
    font-size:17px ;
    border: 1px solid black;
    background-color: white;
}

/* Tablets: from 768px to 1024px */
@media (max-width: 768px)  {
    .photo img{
        width: 135px;
        height: 200px;
    }
    .button{
        width: auto;
        height: auto;
        font-size: 12px;
    }
    .search-outer{
        width:90%;
    }
    .search-button{
        width: auto;
        height: 30px;
    }
    .right-btn button{
        width: 70px;
        height: auto;
    }
    .left-ttle h2{
      font-size: 20px;
    }
    .select-type-select {
        width: 70%;
        height: 30px;
        border-radius: 3px;
    }
}

@media  screen and  (min-width: 768px) and (max-width: 1024px){
    .photo img{
        width: 250px !important;
        height: 400px !important;
    }
    .outer{
        width: 100% !important;
    }
    .search-outer{
        width: 50%;
    }
    .button{
        border-radius: 5px;
        margin: 5px;
    }
}

/* Desktop and larger devices */
@media (min-width: 1024px) {
    .photo img{
        width: 420px !important;
        height: 630px!important;
    }
    .outer{
        width: 100% !important;
    }
    .search-outer{
        width: 50%;
        height: 50px;
    }
}

/* ----------------Read Book CSS------------------- */
.view-novel-outer-ebook{
    width: 100%;
    height:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.left-photo-outer{
    width:50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-photo-outer img{
    height:550px;
    width: 400px;
    padding-top: 50px;
    padding-left: 50px;
    border-radius: 10px;
}

.right-desc-outer-ebook{
    flex: content;
    height: auto;
    padding:10px 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.right-desc-outer-ebook p{
    font-size: 30px;
    text-align: start;
}

.pricing-card{
    font-size: 30px !important;
}

.read-button-outer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.read-button-outer button{
    width: auto !important;
    padding: 5px !important;
    font-size: 20px !important;
    /*background-color: #40ba51 !important;*/
    border-radius: 7px !important;
    border: 1px solid white;
}

a{
    text-decoration: none;
    color: #fffefe;
    text-shadow: 0 0 2px #fffefe;
}

span{
    margin-left: 30px;
    color: red;
    font-weight:600;
}

.Demo__container{
    margin-top: 15px;
}
.mirror-wall{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.mirror-wall img{
    width: 30%;
    height: 140px;
    border-radius: 8px;
}

.comments-section {
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: #090909;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
}
.comments-section::-webkit-scrollbar {
    display: none;
}
.comments-section {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.comments-list {
    background-color: #f3eeee;
    width: 60%;
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    flex-direction: column;
}

.comment {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comment-header {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.comment-header-left{
    width: 50%;
    display: flex;
    align-items: start;
    justify-content: start;
}

.comment-descriptions{
    width: inherit;
    /*//padding-left: 60px;*/
    display: flex;
    align-items: start;
    justify-content: start;
}
.comment-descriptions p{
    text-align: start;
}

.comment-header-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comments-form-outer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*background-color: white;*/
}

.comments-form {
    width: 60%;
    margin-top: 20px;
}

/* Phones: less than 768px */
@media (max-width: 768px) {
    .left-photo-outer, .right-desc-outer-ebook {
        width: 100%;
        margin-left: 0;
        height: auto;
    }
    .left-photo-outer img{
        width: 100%;
        height: 100%;
        margin-left: -20px;
    }
    .view-novel-outer-ebook{
        width: 100%;
        flex-direction: column;
    }
    .comments-section {
        width: 100%;
    }
    .comments-list {
        width: 100%;
    }
    .comments-form {
        width: 100%;
    }

}

/* ---------------Read Preview---------------- */
/*new code */
.pdf-container {
    display: flex;
    flex-direction: column; /* Ensure vertical stacking */
    align-items: center; /* Center the pages horizontally */
    width: 100%;
}

.pdf-page {
    margin-bottom: 20px; /* Space between pages */
}

.wrap {
    overflow-y: auto; /* Enable vertical scrolling */
    height: 92vh; /* Occupy the full viewport height */
}

/*new code end*/
/*.bar{*/
/*    max-width: 100%;*/
/*    height: 90vh;*/
/*    flex-direction: column;*/
/*    display: flex;*/
/*    align-items: flex-start;*/
/*    padding-left: 20px;*/
/*    margin-bottom: 30px;*/
/*    margin-top: 5px;*/
/*}*/

/*.wrap{*/
/*    width: 100%;*/
/*    user-select: none;*/
/*    display: flex;*/
/*    justify-content:center;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*}*/

/*.pdf-container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    overflow: auto;*/
/*    padding: 10px;*/
/*}*/

/*.pdf-page {*/
/*    height: auto;*/
/*    display: block;*/
/*    margin: auto;*/
/*    width: auto;*/
/*}*/
/*.controls {*/
/*    display: flex;*/
/*    justify-content:center;*/
/*    align-items: center;*/
/*    max-width: 100%;*/
/*    margin: 10px auto;*/
/*}*/

/*.controls button {*/
/*    padding: 10px 20px;*/
/*    font-size: 20px;*/
/*    cursor: pointer;*/
/*}*/

/*@media (max-width: 1200px) {*/
/*    .controls button {*/
/*        padding: 8px 16px;*/
/*        font-size: 14px;*/
/*    }*/
/*}*/

/* Phones: less than 768px */
@media (max-width: 768px) {
    .bar{
        width: 100%;
        height: 5rem;
    }
    /* .wrap{
        width: 100vw;
    } */
    .controls button {
        padding: 6px 12px;
        font-size: 12px;
    }
}
@media (max-width: 480px) {
    .controls {
        flex-direction: column;
        align-items: center;
    }

    .controls button {
        width: 80%;
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
    }
}