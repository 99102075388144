.nav-bar {
    max-height: 70px !important;
    max-width: 100% !important;
}

.nav-outer {
    /*background: hsl(0, 56%, 98%) !important;*/
    background: rgb(0, 19, 26) !important;
    background: none;
}

.nav-container {
    max-width: 100%;
}

.nav-header {
    font-size: 40px;
    color: #ff0000 !important;
}

.nav-header:hover {
    color: white !important;
}

.right-outer {
    margin-left: 3% !important;
    flex: 1;
}

.link-page p {
    position: relative;
    font-size: 19px !important;
    /*color: rgb(9, 9, 9);*/
    color: #ff0000 !important;
    margin: 0 10px;
    text-decoration: none;
    overflow: hidden;
}

.link-page p {
    color: #ff0000 !important;
    margin: 10px 20px;
}

.link-page p:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.link-page p:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.link-page p:hover {
    /*color: #0e0e0e;*/
    color: white;
    text-decoration: none;
}

.show-username {
    font-size: 17px;
    color: #ff0000;
}

.togs {
    background-color: rgb(252, 251, 251) !important;
    border: 1px solid rgb(251, 247, 247) !important;
}

.navbar-toggler {
    border: 1px solid red !important;
    border-radius: 5px !important;
    color: white !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

@media screen and (max-width: 768px) {
    p, h1 {
        font-size: 8px;
    }

    .link-page a {
        font-size: 17px;
    }

    span {
        margin-left: -10px;
    }

    .nav-bar {
        width: 100% !important;
    }

    .nav-header {
        font-size: 27px;
    }

    .show-username {
        padding-left: 1px;
    }
}

/*@media  screen and  (min-width: 768px) and (max-width: 1024px){*/
/*  p,h1{*/
/*    font-size: 15px;*/
/*  }*/
/*}*/