.outer {
    background: linear-gradient(60deg, #f6c4c3, #f0edef);
    max-width: 100% !important;
    height: auto;
    margin-bottom: 5%;
}

.add-container {
    max-width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    padding: 10px 20px 10px 20px;
}
.news-container {
    display: inline;
    flex-direction: column;
}

.news-strip {
    border: 6px solid red;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
}

.news-strip p {
    color: white;
    text-align: start;
    line-height: 35px;
    font-weight: 600 !important;
    font-size: 20px;
}

.left-add-container {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    flex-direction: column;
    /*pointer-events: none;*/

}

.picture-rim {
    border-radius: 15px !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.picture-rim img {
    border-radius: 15px !important;
    user-select: none;
}
.picture-rim img:hover{
    opacity: 60%;
    transition: all 0.1s;
}

.right-add-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-banners {
    width: 80%;
    height: 70%;
    /* border: 1px solid yellow; */
    border-radius: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.banner-picture{
    width: 1050px;
    height: 250px;
    border-radius: 10px !important;
}
/*------------------*/

.gallery-container {
    width: 100%;
    height: auto;
}

.news-list {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 40px;
    padding: 5px 10px 5px 10px;
}

.news-outer {
    width: 30%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column !important;
}

.left-news-outer-view .left-news-outer-img .left-news-outer-pictureRim .left-news-outer-list {
    padding: 20px;
    margin-right: 20px;
}

.right-news-outer {
    margin-top: 40px;
    flex: 1;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    height: auto;
    user-select: none;
}

.right-news-outer-picture-rim {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    user-select: none;
    width: 100%;
    text-align: start;
    line-height: 25px;
    padding-top: 20px;
}

.right-news-outer-picture-rim p {
    color: black;
    text-align: start;
}

.right-news-outer-picture-rim h2 {
    color: red;
}

.right-news-outer-picture-rim-btn {
    border: 1px solid red;
    font-size: 17px;
    margin-top: 5px;
    color: black;
    font-weight: 500;
}

.right-news-outer-picture-rim-btn:hover {
    border: 1px solid #0b0a0a;
    color: #f80505;
}

.left-news-outer-img img {
    border: 1px solid rgb(241, 237, 237);
    border-radius: 8px;
    align-items: center;
}
.left-news-outer-img img:hover {
    opacity: 70%;
    transition: all 0.1s;
    cursor: pointer;
}

.left-news-outer-view img {
    border: 1px solid rgb(241, 237, 237);
    border-radius: 8px;
    align-items: center;
}

.left-news-outer-pictureRim img {
    border: 1px solid rgb(241, 237, 237);
    border-radius: 8px;
    align-items: center;
}

/*.left-news-outer-list{*/
/*    width: 100%;*/
/*    border: 1px solid red;*/
/*    display: flex;*/
/*    align-items:center;*/
/*    justify-content: center;*/
/*}*/

.left-news-outer-list img {
    border: 1px solid rgb(241, 237, 237);
    border-radius: 8px;
    align-items: center;
    width: 100%;
}

.left-news-outer-list img:hover {
    cursor: pointer;
    opacity: 70%;
    transition: 0.1ms;
}

.right-news-outer h2 {
    font-size: 30px;
    font-weight: 500;
    color: red;
}

.right-news-outer p {
    font-size: 20px;
    text-align: start;
    line-height: 25px;
    color: white;
}

.right-news-outer button {
    border: 1px solid red;
    font-size: 17px;
    margin-top: 5px;
    color: white;
}

.right-news-outer button:hover {
    border: 1px solid white;
    color: red;
}

.button-bottom-next {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
}

.button-news-bottom {
    color: white !important;
}


/* Phones: less than 768px */
@media (max-width: 768px) {
    .add-container {
        width: 100%;
        flex-direction: column;
        /*gap: 20px;*/
    }

    .news-container {
        width: 100%;
    }

    .left-add-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .picture-rim {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .picture-rim img {
        width: 100% !important;
        height: 100% !important;
    }

    .right-add-container {
        width: 100% !important;
        height: 200px;
    }

    .add-banners {
        height: 100% !important;
        width: 100% !important;
        margin: 0;
    }
    .add-banners img{
        height: 100%;
        width: 100% ;
    }

    .news-outer {
        width: 100%;
        gap: 20px;
    }

    .news-list {
        flex-direction: column;
    }

    .left-news-outer-img {
        width: 100%;
    }
    .left-news-outer-img:hover{
        opacity: 70% !important;
        transition: all 0.1s !important;
    }

    .left-news-outer-img img {
        width: 100%;
    }
    .left-news-outer-img img:hover {
        opacity: 70% !important;
        transition: all 0.1s !important;
    }
    .photo-item:hover{
        opacity: 70% !important;
        transition: all 0.1s !important;
    }

    .right-news-outer {
        width: 100%;
    }

    .right-news-outer h2 {
        font-size: 20px;
        font-weight: 600;
    }

    .left-news-outer-list {
        width: 100%;
    }
}

/* Tablets: from 768px to 1024px */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .add-container {
        width: 100%;
    }

    .left-add-container, .picture-rim {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-add-container {
        width: 100%;
        height: 200px;
    }

    .add-banners {
        height: 150px;
        width: 100%;
    }

    .news-outer {
        width: 45%;
        gap: 20px;
    }

    .news-list {
        flex-wrap: wrap;
    }

    .left-news-outer-img {
        width: 100%;
    }

    .left-news-outer-img img {
        width: 100%;
    }

    .right-news-outer {
        width: 100%;
    }

    .right-news-outer h2 {
        font-size: 23px;
        font-weight: 600;
    }

    .picture-rim img {
        width: 100% !important;
        height: 100% !important;
    }
}

/* Desktop and larger devices */
@media (min-width: 1024px) {
    .gallery-container {
        width: 100%;
    }

    .news-list {
        width: 100%;
    }

    .news-outer {
        width: 30%;
        flex-direction: row;
        /*gap: 20px;*/
    }

    .left-news-outer-img {
        width: 100%;
    }

    .left-news-outer-img img {
        width: 100%;
    }

    .right-news-outer {
        width: 100%;
    }

    .right-news-outer h2 {
        font-size: 23px;
        display: flex;
    }


}


/* View news section  */

.view-news-outer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    user-select: none;
}

.read-picture-rim-outer:hover{
    border: 2px solid red;
}
.read-picture-rim-outer{
    border: 2px solid black !important;
}

.left-news-outer-view {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-news-outer-view img {
    width: 40%;
    height: 90%;
}

.right-news-desc-outer {
    width: 100% !important;
    height: auto;
    padding: 2% 10% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;
}

.news-description-body {
    display: flex;
    align-items: start;
    justify-content: start;
}

/*.news-description-body p {*/
/*    text-align: start;*/
/*    font-size: 20px;*/
/*}*/

.topic {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: start;
}

.topic h2 {
    color: red;
}

.news-description-body p {
    color: #050505;
    font-size: 20px;
    text-align: start !important;
}

.button-outer {
    margin-left: 40%;
    align-items: center;
}

/* Phones: less than 768px */
@media (max-width: 768px) {
    .left-news-outer-pictureRim {
        width: 100%;
        margin-left: 0;
        height: auto;
    }

    .right-news-desc-outer {
        width: 90%;
        margin-left: 0;
        height: auto;
    }

    .left-news-outer-pictureRim img {
        width: 100%;
    }

    .right-news-desc-outer p {
        font-size: 15px !important;
        text-align: start;
    }

    .right-news-desc-outer h2 {
        text-align: start;
        font-size: 25px !important;
    }

    .button-outer {
        margin-left: 20%;
    }

    /* .left-photo-outer{
        width: 25%;
        height: 650px;
    } */
}

