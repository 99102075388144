.main-outer{
    height: auto;
    padding-top:30px ;
}

.privacy-controler{
    /*-webkit-box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.75);*/
    /*-moz-box-shadow: 6px 1px 2px 2px rgba(0,0,0,0.75);*/
    /*box-shadow: 2px 1px 2px 2px rgba(0,0,0,0.75);*/
    width: 80%;
    min-width: 300px;
    padding: 20px;
    margin: 20px;
    border: 1px solid black;
    border-radius: 1rem;
    /*background: #f4f0f0;*/
}
.privacy-inner-outer{
    /*border: 1px solid red;*/
    width: 100%;
    height: auto;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    font:  14px black;
    padding: 10px 30px;
    line-height: 26px;
}

.privacy-inner-outer p{
    text-align:left;
}

.privacy-inner-outer ol{
    font-size: 18px;
    line-height: 30px;
}

.privacy-inner-outer ul{
    font-size: 18px;
}
.privacy-inner-outer li {
    line-height: 30px;
}

