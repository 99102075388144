* {
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    margin: 0 10px;
}

.main-outer-audio {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.left-audio-outer {
    height: auto;
    min-width: 50%;
    display: block;
    position: sticky;
    margin-top: 20px;
    padding: 20px 40px 20px 40px;
}

.left-audio-outer p {
    font-size: 25px;
}

.audio-player {
    width: 100%;
    height: inherit;
}

.right-desc-outer {
    flex: content;
    height: auto;
    padding: 10px 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.right-desc-outer p {
    text-align: start;
    color: black;
}

.right-photo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 15px;
    width: auto;
    cursor: pointer;
}

.pricing-card {
    font-size: 25px;
}

.audio-book-list {
    width: 70%;
    max-height: 220px;
    overflow-y: auto;
    /*border: 1px solid #2a58ef;*/
}

.audio-book-list::-webkit-scrollbar {
    display: none;
}

.audio-book-list {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.right-photo img {
    width: 50px;
    /*height: 75px;*/
}

.right-photo p {
    margin: 25px 15px;
    color: black !important;
}

.audio-description {
    width: 100%;
    flex: content;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid red;
}

.read-button-outer button {
    width: auto;
    padding: 10px;
    font-size: 20px;
    border-radius: 10cap;
}

a {
    text-decoration: none;
}

p {
    color: black;
}
.me-auto{
    font-size: 18px;
}
.mirror-wall{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.mirror-wall img{
    width: 30%;
    height: 140px;
    border-radius: 8px;
}
.comments-section {
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: #ffffff;
    overflow-y: auto;
    /*overflow-y: hidden;*/
    padding: 10px;
    box-sizing: border-box;
}

.comments-section::-webkit-scrollbar {
    display: none;
}

.comments-section {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.comments-list {
    background-color: #f3eeee;
    width: 60%;
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    flex-direction: column;
}

.comment {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comment-header {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.comment-header-left {
    width: 50%;
    display: flex;
    align-items: start;
    justify-content: start;
}

.comment-descriptions {
    width: inherit;
    /*padding-left: 60px;*/
    display: flex;
    align-items: start;
    justify-content: start;
}

.comment-descriptions p {
    text-align: start;
}

/* Phones: less than 768px */
@media (max-width: 768px) {
    .left-audio-outer, .right-desc-outer {
        width: 100%;
        margin-left: 0;
        height: auto;
    }

    .right-photo p {
        font-size: 20px;
    }

    .main-outer-audio {
        flex-direction: column;
    }
    .mirror-wall img{
        width: 90%;
        height: 120px;
        border-radius: 8px;
    }
    .comments-list {
        width: 90%;
    }
    .comments-form-outer{
        max-width: 100%;
        padding: 2px 10px 2px 10px;
    }
}

/*@media (min-width: 768px) {*/
/*      .left-audio-outer{*/

/*      }*/
/*      .right-desc-outer {*/
/*          height: auto;*/
/*      }*/
/*      .right-photo p{*/
/*        font-size: 20px;*/
/*      }*/
/*}*/

.audio-player {
    background: hsl(330, 1%, 69%);
}

.inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* audio info */
.audio-info {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.text {
    color: #f50;
}

.title {
    color: #fffbfb;
    font-weight: 100;
    font-size: 32px;
    margin-bottom: 0;
    padding: 2px;
    line-height: 37px;
}

.desc {
    color: black;
    font-weight: 100;
    font-size: 20px;
}

/* audio image */
.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.audio-image {
    width: 280px;
    height: 400px;
    background: #1e1e1e;
}

.audio-icon {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #c7c3c3;
}

.audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

/* controls */
.controls-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    width: 100%;
}

.controls {
    width: 65%;
}

.volume {
    flex: 1;
}

.controls button {
    border: none;
    background: hsl(0, 0%, 78%);
    margin-right: 8px;
    cursor: pointer;
}

.volume button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
}

button svg {
    font-size: 20px;
}

.volume {
    display: flex;
    align-items: center;
}

.volume button {
    margin: 0;
}

.progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

/* time */
.time {
    color: #333;
    font-size: 13px;
    line-height: 46px;
}

.time.current {
    color: #f50;
}

@media (max-width: 768px) {
    .controls {
        width: 100%;
        margin-left: 0;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        /* padding-left: 30px; */
    }

    .controls button {
        border: none;
        background: hsl(0, 0%, 78%);
        margin-right: 1px;
        cursor: pointer;
    }

    .volume {
        width: 10% !important;
        margin-left: 10px;
        height: auto;
        padding-left: 20px;
    }

    .audio-image {
        width: 60%;
        height: 50%;
    }

    .title {
        font-size: 25px;
    }

    .desc {
        color: black;
        font-weight: 100;
        font-size: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .controls {
        width: 100%;
        margin-left: 0;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        /* padding-left: 30px; */
    }

    .volume {
        width: 10% !important;
        margin-left: 70%;
        padding-left: 20px;
        /*border: 1px solid red;*/
    }
}

@media (min-width: 1024px) {
    .controls {
        width: 100%;
        margin-left: 0;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }

    .volume {
        margin-left: 70%;
        height: auto;
        padding-left: 20px;
        width: 10% !important;
    }
}

/* Top bar */
.top__bar {
    font-size: 0.875rem;
    max-width: 100%;
    padding-bottom: .5rem;
    background-color: #575656;
    flex-direction: column;
    position: sticky;
    align-items: flex-start;
    display: flex;
    padding-left: 20px;
    margin-top: 5px;
}

.top__bar p {
    margin: 0;
}
  