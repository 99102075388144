.main-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: white;
}
.form-controler{
    -webkit-box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.75);
    box-shadow: 6px 13px 9px 2px rgba(0,0,0,0.75);
    width: 600px !important;
    min-width: 300px;
    padding: 20px;
    margin: 20px;
    border: 1px solid black;
    border-radius: 1rem;
    background: #f4f0f0;
}
.form-lable{
    color: black;
    background: #ece4e4;
    font-size: 15px;    
}
h1{
    text-align: center;
    color: blue;
}
p{
    text-align: center;
}

.button-style{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(177, 228, 21);
    border-radius: 40px !important;
    width: 90%;
    margin-left: 5%;
}
@media (min-width: 768px) {
    /*.toaster{*/
    /*    min-width: '500px';*/
    /*    height: '20px';*/
    /*}*/
}
