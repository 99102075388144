.viewLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #141e30;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  position: fixed;
  opacity: 1;
}
