.container{
  /*background: linear-gradient(60deg, #f6c4c3, #f0edef);*/
  height: auto;
}
.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .photo-list {
    display: flex;
    overflow-x: auto;
    padding: 10px;
  }

  button {
    margin: 0 10px;
  }
.outer{
    width: 100%;
    height: auto;
    /*border: 1px solid red;*/
}

.inner-outer{
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap:35px;
    margin:7px 0 30px 0;
    padding-top:80px;
    padding-bottom:80px;
}
.category-outer{
    width: 300px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid red;
    border-radius: 10px;
    color: white;

}
.category-outer img{
    width: 100%;
    height:92.5%;
    border-radius: 10px 10px 0px 0px;
}
.category-outer img, h2:hover{
    cursor: pointer;
}
.category-outer h2{
  color: white;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
}

@media (max-width: 768px) {
    .category-outer{
        width: 200px;
        height: 270px;
    }
}

  