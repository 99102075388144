.footer-top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #090909;
    max-width: 100% !important;
    min-height: 150px;
    margin-top: -50px !important;
}

.footer-bottum {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /*color: #0c0c0c;*/
    color: red;
    min-height: 30px;

}

.footer-top p, .footer-bottum p{
    /*color: black !important;*/
    color: red !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.inside-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15%;
    margin-left: 100px;
}

.footer-links-left {
    max-width: 30%;
    margin-bottom: 5px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding-left: 200px;
}

.footer-links-left p {
    text-align: start;
}

.footer-links-midle {
    max-width: 30%;
    margin-bottom: 5px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.footer-links-right {
    max-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
}

.footer-links-left a {
    color: #f60606 !important;
    text-decoration: none;
}
.footer-links-midle a {
    color: #f60606 !important;
    text-decoration: none;
}

.f-text {
    /*font-weight: bold;*/
    font-size: 25px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .footer-top {
        flex-direction: column !important;
        align-items: center;
        margin-bottom: 40px;
        margin-top: -10px !important;
    }

    .footer-links-left {
        max-width: 100%;
        padding-left: 0px;
    }

    .footer-links-midle {
        max-width: 100%;
    }

    .footer-links-right {
        max-width: 100%;
    }

    .inside-row {
        margin-left: 0px;
    }

}
  
    
    
    
